$(document).ready(function () {
    fnRemovelocalStorage();
});

// To remove local storage data
function fnRemovelocalStorage() {
    localStorage.removeItem('localPupilData');
    localStorage.removeItem('resetProductFlag');
    localStorage.removeItem("fullAddress_1");
    localStorage.removeItem("fullAddress_2");
    localStorage.removeItem('resetProducerName');
    sessionStorage.removeItem("showwhenbackPressed_1");
    sessionStorage.removeItem("showwhenbackPressed_2");
    sessionStorage.removeItem("showEditAddressManually");
    sessionStorage.removeItem("radioButtonSelected");
}